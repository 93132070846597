import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';

// TODO Delete CFDI4_SHIPPERS const when a proper method is created for CFDI 4.0 version
const CFDI4_SHIPPERS = [
  '63daf0cda86a0cf79f3583ed',
  '64138eab2adf3246b4d40175',
  '632e26831416b85307bec716',
  '632e21f8c902c63ed8eaf2a1',
  '5f2c3bcefb19db512bc2663f',
  '5f4553fe465e0535da27b45c',
  '6273e2e584b3ad23e57d9bc3',
  '62753d12212f9de746d16ee6',
  '634051aee4da9f9319547177',
  '6331b745c902c68fa1eaf5eb',
  '5f877f16b6c21a48cf2c49ff',
  '6037d9cc4fe6993def62da45',
  '63dd4ff269ccfd2a005bda09',
  '63e2cce825500f8f21cd2fa0',
  '5f80ae62b26d44c7b27e3c7a',
  '673fa09112a6297298772836',
  '6776f6337babcb2e512a1995'
];

@Injectable()
export class CmrConsignmentNoteService {

  constructor(
    private _appService: AppService
  ) {}

  /**
   * @description Validate if the current userOId is valid for CFDI 4.0 version
   * @returns {Promise<boolean>} If the current userOId must stamp in CFDI 4.0 version
   */
  public async shipperStampsOnCFDI4(): Promise<boolean> {
    const currentShipper = this._appService.getShipperOid();
      return CFDI4_SHIPPERS.includes(currentShipper);
  }

}
